<template>
    <div class="container">

                        <Address-form @submitted="update" :is-edit="true"></Address-form>

    </div>
</template>

<script>
import AddressForm from '../../components/AddressForm.vue'

export default {
    components: { AddressForm },
    middleware: ['auth','user'],
    methods: {
        update (data) {

            let item = data.item;

          this.$http.put('/api/address/' + this.$route.params.id, item)
                 .then(({ data }) => {
                     console.log("EditAddressPage");
                     this.$router.go(-1)
                     //this.$router.push({ name: 'address' })
                     this.$toast.success(data.message, "Success")
                 })
                 .catch(({ response }) => {
                     console.log(response);
                     //EventBus.$emit('error', response.data.errors)
                 })
                 
        }
    }
}
</script>